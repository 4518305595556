import { navigate } from 'gatsby';
import React, { FC } from 'react';

import App from '../components/layout/App';

// eslint-disable-next-line @typescript-eslint/ban-types
const requireAuth =
  <P extends { location: any }>(
    ComposedComponent: FC<P & { user?: any }>,
    options?: {
      layout?: boolean;
    },
    // eslint-disable-next-line react/display-name
  ): FC<P> =>
  // eslint-disable-next-line react/display-name
  ({ location, ...props }) => {
    const layout = options?.layout || false;
    let isLogged = false;
    const password =
      'undefined' !== typeof localStorage && localStorage.getItem('password');

    if (process.env.GATSBY_ACCESS_PASSWORD === password) {
      isLogged = true;
    }

    if ('undefined' !== typeof window && !isLogged) {
      navigate('/login');
    }

    if (layout) {
      return (
        <App isLogged={isLogged}>
          <ComposedComponent {...(props as P)} location={location} />
        </App>
      );
    }

    return <ComposedComponent {...(props as P)} location={location} />;
  };

export default requireAuth;
