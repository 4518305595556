import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/perliia/perliia/src/templates/default.mdx.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Conditions d’utilisations`}</h1>
    <p>{`dernière maj : 22 octobre 2021`}</p>
    <p>{`Les présentes conditions d’utilisation constituent un contrat entre MARNADIL et les utilisateurs de ses outils Le Chemin Des Mûres: API et services logiciels. Il a force obligatoire et décrit les droits et responsabilités des parties dans l’utilisation de ces services.`}</p>
    <h2>{`Conditions d’utilisation API`}</h2>
    <h3>{`Définitions`}</h3>
    <p>{`Sous réserve des restrictions ci-dessous, Le Chemin Des Mûres (“nous”) concède aux utilisateurs une licence d’utilisation non exclusive sur l’API; non cessible et restreinte pour accéder à ses API ainsi qu’à sa documentation. Vous ne pouvez pas céder, louer, concéder en sous-licence, redistribuer ou restreindre l’accès de nos API.`}</p>
    <p>{`Le Client ou Organisation est l’entité ou structure identifiée dont dépend l'Utilisateur (“vous”) lors de l’utilisation de nos services. L’utilisateur est identifié via sa clé d’accès. Il est rattaché à une Organisation et son type de clé ouvre les droits associés. Utiliser une clé engage l’Organisation selon les termes du présent contrat, l’Utilisateur doit s’assurer d’avoir les pouvoirs requis au sein de sa propre Organisation pour un tel engagement.`}</p>
    <h3>{`Règles d’accès`}</h3>
    <p>{`Vous n’êtes pas autorisé à :`}</p>
    <ul>
      <li parentName="ul">{`accéder à nos API ou à notre documentation en violation de toute loi ou réglementation`}</li>
      <li parentName="ul">{`accéder à nos API d’une manière qui compromet, interrompt ou contourne l’un de nos processus techniques ou l’une des mesures de sécurité associées au service.`}</li>
      <li parentName="ul">{`accéder à nos API d’une manière qui présente des vulnérabilités pour la sécurité des autres utilisateurs du service`}</li>
      <li parentName="ul">{`accéder à nos API d’une manière qui teste la vulnérabilité de nos systèmes ou réseaux`}</li>
      <li parentName="ul">{`accéder à nos API ou à notre documentation afin de reproduire ou concurrencer nos services`}</li>
      <li parentName="ul">{`tenter de trouver le code source (par reverse engineering ou autre), les secrets commerciaux ou le savoir faire de nos API et services`}</li>
      <li parentName="ul">{`tenter d’utiliser nos API d’une manière qui dépasse les limites d’utilisation ou constitue une utilisation excessive ou abusive`}</li>
    </ul>
    <p>{`Un abonnement est requis pour chaque utilisateur autorisé. Cet abonnement est associé à une clé d’accès qui permet d’identifier les requêtes comme émanant de l'utilisateur-client`}</p>
    <p>{`le prix de l’abonnement et le montant à acquitter pour chaque requête sur nos API dépend du type de clé utilisée pour s’identifier lors de sa transmission. La requête en question doit suivre les règles d’utilisation et restrictions propres à ce type de clé`}</p>
    <h4>{`clé académique`}</h4>
    <p>{`L’utilisation de cette clé est gratuite dans le cadre de son usage spécifique.
L’usage de ce type de clé est réservé aux organismes de recherche publique ou privée dûment accrédités, à des fins d’éducation ou de recherche dans le domaine informatique uniquement et sans but commercial ou lucratif ou de production de connaissance en dehors du domaine informatique.`}</p>
    <h3>{`Niveau de service`}</h3>
    <p>{`Les API et documentations associées sont actuellement en version bêta. Cela signifie que malgré l’attention portée à leur qualité ils sont disponibles tels quels, sans garanties ou engagements de notre part concernant leur disponibilité ou temps de réponse.`}</p>
    <p>{`Nous vous invitons à nous faire part de toute erreur, dysfonctionnement (même supposé), perturbation, défaut qui entacherait votre expérience, nous apprécions et porterons également attention sur vos recommandations et suggestions.`}</p>
    <h3>{`Données`}</h3>
    <p>{`La propriété des données transmises reste celle du Client et il n’en sera fait ni commerce ni divulgation auprès de tiers. Le Chemin Des Mûres n’est pas un service de stockage, bien que ces données soient accessibles et peuvent être récupérées par tout membre de l’organisation cliente (via sa clé unique), nous n’offrons pour le moment aucune garantie sur ces fonctionnalités (version bêta).`}</p>
    <p>{`Les données transmises sont stockées par nos soins chez nos partenaires (Google Cloud). Nous nous réservons le droit d’utiliser ces données de manière anonymisée et à des fins de sécurité ou de statistiques uniquement.`}</p>
    <p>{`Il n’est pas permis d'échanger via nos API des données personnelles telles que visées par le règlement général sur la protection des données (RGPD).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      