import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import requiredAuth from '../utils/requireAuth';

const PageSC = styled.div`
  max-width: 800px;
  p:not(:last-child),
  ul {
    margin-bottom: 1.5rem;
    text-align: justify;
  }

  ul {
    li {
      list-style: disc inside;
    }
  }
`;
const DefaultMdx: FC<PageProps> = props => {
  const { children } = props;

  return <PageSC className="m-10 md:mx-auto">{children}</PageSC>;
};

export default requiredAuth(DefaultMdx, { layout: true });
